import React from 'react'


const Gallery = ({cats}) => (
    <div className="row text-center">
      {cats &&
        cats.map((c, k) => {
          return (
            <div key={k} className="col-md-3 mb-3">
              <div className="token"></div>
              <div>{c}</div>
            </div>
          );
        })}
    </div>
);

export default Gallery;