import React from "react";
import { Anchor, Box, Header, Nav } from "grommet";
import { Link } from "react-router-dom";

const NavBar = ({ acc, isAdmin, siteMap }) => {
  const menu = [];

  if (acc) {
    menu.push({ label: "HOME", href: siteMap.Home });
    menu.push({ label: "ABOUT", href: siteMap.About });
    menu.push({ label: "ROADMAP", href: siteMap.RoadMap });
    menu.push({ label: "MINT", href: siteMap.Mint });
    menu.push({ label: "MY TOKENS", href: siteMap.MyTokens });

    if (isAdmin) menu.push({ label: "ADMIN", href: siteMap.Admin });
  }

  return (
    <Header background="main-1" pad="small">
      <Box direction="row" align="center" gap="small">
        <Anchor as={Link} color="nav-accent" to="/">
          DeadcatBounce
        </Anchor>
      </Box>
      <Nav direction="row">
        {menu.map((item) => (
          <Anchor
            as={Link}
            label={item.label}
            to={item.href}
            key={item.label}
            color="nav-link"
          />
        ))}
      </Nav>
    </Header>
  );
};

export default NavBar;
