import React, { useState, useEffect } from "react";
import { Anchor, Box, Footer, Grommet, Main, Text } from "grommet";

import { grommet, dark } from "grommet/themes";
import { Routes, Route } from "react-router-dom";

import Gallery from "./Gallery";
import MintSection from "./MintNft";
import NavBar from "./NavBar";
import PageFooter from "./Footer";
import { dcbTheme } from "./theme";

import "./App.css";
import DeadcatBounce from "../build/DeadcatBounce.json";

const loadAcc = async () => {
  const web3 = window.web3;
  // TODO: make responsive to acc change
  const acc = await web3.eth.getAccounts();
  return acc[0];
};

const loadContract = async () => {
  const web3 = window.web3;
  // TODO: make responsive to network change
  const networkId = await web3.eth.net.getId();
  const networkData = DeadcatBounce.networks[networkId];
  if (networkData) {
    const abi = DeadcatBounce.abi;
    const address = networkData.address;
    const cont = new web3.eth.Contract(abi, address);
    //console.log("Cont: ", cont);
    return cont;
  }
  window.alert("Smart contract not available on selected network!");
  return null;
};

const siteMap = {
  Home: "/",
  About: "/about",
  RoadMap: "/road-map",
  Mint: "/mint",
  Admin: "/admin",
  MyTokens: "/my-tokens",
};

const MintPage = ({ onMint, cats }) => (
  <>
    <MintSection onMint={onMint} />
    <hr />
    <Gallery cats={cats} />
  </>
);

const MyTokensPage = ({ cats }) => (
  <>
    <Gallery cats={cats} />
  </>
);

const App = () => {
  const [acc, setAcc] = useState();
  const [cont, setCont] = useState();
  const [cats, setCats] = useState([]);

  const handleOnMint = async (catName) => {
    console.log("catName: ", catName);
    await cont.methods
      .mint(catName)
      .send({ from: acc })
      .once("receipt", (r) => {
        setCats([...cats, catName]);
      });
  };

  useEffect(() => {
    (async () => {
      const acc = await loadAcc();
      setAcc(acc);

      const cont = await loadContract();
      setCont(cont);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (cont) {
        const supply = await cont.methods.totalSupply().call();
        //console.log("supply: ", supply);
        const catsBuff = [];
        for (let i = 0; i < supply; i++) {
          const cat = await cont.methods.cats(i).call();
          catsBuff.push(cat);
        }
        setCats(catsBuff);
      }
    })();
  }, [cont]);

  return (
    <Grommet theme={dcbTheme}>
      <NavBar acc={acc} siteMap={siteMap} isAdmin={true} />
      <div className="container-fluid body-container">
        <Routes>
          <Route
            path={siteMap.Mint}
            element={<MintPage cats={cats} onMint={handleOnMint} />}
          />
          <Route
            path={siteMap.MyTokens}
            element={<MyTokensPage cats={cats} />}
          />
        </Routes>
      </div>
      <PageFooter siteMap={siteMap} />
    </Grommet>
  );
};

export default App;
