import React from "react";
import ReactDOM from "react-dom";
import Web3 from "web3";
import { BrowserRouter as Router } from "react-router-dom";
//import reportWebVitals from './reportWebVitals';

import App from "./components/App";

const loadWeb3 = async () => {
  // load web3, connect to wallet
  if (window.ethereum) {
    window.web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
  } else if (window.web3) {
    window.web3 = new Web3(window.web3.currentProvider);
  } else {
    window.alert(
      "Non-Ethereum browser detected. You should consider trying MetaMask!"
    );
  }
};

loadWeb3().then(() => {
  ReactDOM.render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>,
    document.getElementById("root")
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
