import React, { useState } from "react";

const MintSection = ({onMint}) => {
    const [catName, setCatName] = useState("");

    return (
    <div className="row">
      <main
        role="main"
        className="col-lg-12 d-flex justify-content-center text-center"
      >
        <div className="content mr-auto ml-auto">
          <h1>Mint Cat</h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onMint(catName);
              setCatName("");
            }}
          >
            <input
              type="text"
              className="form-control mb-1"
              placeholder="Name your Cat"
              value={catName}
              onChange={(e) => setCatName(e.target.value)}
            ></input>
            <input
              type="submit"
              className="btn btn-block btn-primary"
              value="Mint"
            />
          </form>
        </div>
      </main>
    </div>
  )
        };

  export default MintSection;