const dcbTheme = {
  global: {
    colors: {
      background: {
        light: "#ffffff",
        dark: "#000000",
      },
      "main-1": "#000000",
      "main-2": "dark-2",
      "main-3": "#D3D3D3",
      "nav-link": "white",
      "nav-accent": "#32CD32",
      focus: "white",
    },
    font: {
      family: `-apple-system,
           BlinkMacSystemFont, 
           "Segoe UI", 
           Roboto, 
           Oxygen, 
           Ubuntu, 
           Cantarell, 
           "Fira Sans", 
           "Droid Sans",  
           "Helvetica Neue", 
           Arial, sans-serif,  
           "Apple Color Emoji", 
           "Segoe UI Emoji", 
           "Segoe UI Symbol"`,
    },
  },
};

export { dcbTheme };
