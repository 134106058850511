import React from "react";
import { Anchor, Box, Footer, Text } from "grommet";
import { Money } from "grommet-icons";

const FooterAnchor = ({ ...rest }) => (
  <Anchor href="/" size="small" color="nav-link" {...rest} />
);

const getFooterMeta = (siteMap) => [
  {
    Group: "Menu",
    Links: siteMap,
  },
  {
    Group: "Social",
    Links: {
      Twitter: "https://twitter.com",
      Instagram: "https://instagram.com",
      Discord: "https://discord.com",
    },
  },
];

const FooterContent = ({ siteMap }) =>
  getFooterMeta(siteMap).map((item) => (
    <Box gap="medium" key={item[0]}>
      <Text weight="bold" size="small" color="nav-accent">
        {item.Group}
      </Text>
      <Box>
        {Object.keys(item.Links).map((k) => (
          <FooterAnchor key={k}>{k}</FooterAnchor>
        ))}
      </Box>
    </Box>
  ));

const PageFooter = ({ siteMap }) => {
  return (
    <>
      <Footer background="main-1" pad="large">
        <Box direction="row-responsive" gap="xsmall">
          <Box align="center" gap="small">
            <Money color="nav-accent" size="large" />
            <Text alignSelf="center" color="nav-link" weight="bold">
              DeadcatBounce
            </Text>
          </Box>
        </Box>
        <FooterContent siteMap={siteMap} />
      </Footer>
      <Footer
        background="main-2"
        pad={{ horizontal: "large", vertical: "small" }}
      >
        <Box direction="row" gap="small" />
        <Text textAlign="center" size="small">
          © 2021 Copyright
        </Text>
      </Footer>
    </>
  );
};

export default PageFooter;
